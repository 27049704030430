import React, { Component } from 'react';
import { SlideDown } from 'react-slidedown';
import { scroller, Element } from 'react-scroll';
import { ToastContainer } from 'react-toastify';
import Header from './Header';
import TeamContainer from './TeamContainer';
import ApplicationForm from './ApplicationForm';

import 'react-slidedown/lib/slidedown.css';
import './App.scss';

import stoicLogo from './img/team_stoic_logo.svg';
import pcgLogo from './img/sponsor_pcg.png';
import stoicMerch from './img/stoic_merch.jpg';
import stoicMerchT from './img/stoic_merch_t.jpg';

class App extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
      applicationType: 'player',
      shouldSlide: false
    };

    this.toggleForm = this.toggleForm.bind(this);
    this.handleApplicationTypeChange = this.handleApplicationTypeChange.bind(
      this
    );
  }

  toggleForm(event, scroll, selectedApplicationType) {
    this.setState(prevState => {
      // If the form is already open, switch between the types without closing
      if (
        prevState.isOpen &&
        selectedApplicationType &&
        prevState.applicationType !== selectedApplicationType
      ) {
        return {
          ...prevState,
          applicationType: selectedApplicationType,
          shouldSlide: true
        };
      }

      // otherwise toggle the form
      return {
        ...prevState,
        isOpen: !prevState.isOpen,
        applicationType: selectedApplicationType || prevState.applicationType,
        shouldSlide: false
      };
    });

    if (event) {
      event.stopPropagation();
    }

    if (scroll) {
      scroller.scrollTo('formScroll', {
        duration: 800,
        smooth: 'easeInOutQuart',
        offset: -100,
        isDynamic: true
      });
    }
  }

  handleApplicationTypeChange(event) {
    this.setState({
      applicationType: event.target.value,
      shouldSlide: true
    });
  }

  render() {
    const { isOpen, applicationType, shouldSlide } = this.state;

    return (
      <div id="app" style={isOpen ? { overflow: 'hidden' } : {}}>
        <Header />
        <section id="splash">
          <div className="content clearfix">
            <img src={stoicLogo} alt="Team Stoic logo" id="logo" />
            <h1 id="tagline">THE NEXT BIG NAME IN ESPORTS</h1>
            <div id="application_container" className="clearfix">
              <Element name="formScroll" />
              <div
                onClick={event => this.toggleForm(event, !isOpen, 'player')}
                className={
                  'application_button ' +
                  (isOpen && applicationType !== 'sponsor'
                    ? 'application_button_active'
                    : '')
                }
              >
                <span>WORK WITH TEAM STOIC</span>
              </div>
              <div
                onClick={event => this.toggleForm(event, !isOpen, 'sponsor')}
                className={
                  'application_button ' +
                  (isOpen && applicationType === 'sponsor'
                    ? 'application_button_active'
                    : '')
                }
              >
                <span>BECOME A SPONSOR</span>
              </div>
            </div>
          </div>
        </section>
        <section id="form">
          <SlideDown>
            {isOpen ? (
              <ApplicationForm
                onClose={this.toggleForm}
                handleApplicationTypeChange={this.handleApplicationTypeChange}
                applicationType={applicationType}
                shouldSlide={shouldSlide}
              />
            ) : null}
          </SlideDown>
        </section>
        <TeamContainer />
        <section id="sponsors">
          <div id="sponsor_container">
            <a
              className="sponsor"
              href="https://www.pcg-usa.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img alt="Project Consulting Group logo" src={pcgLogo} />
            </a>
          </div>
        </section>
        <section id="merch">
          <img
            id="merch_jersey"
            alt="Team Stoic merchandise jersey"
            src={stoicMerch}
            onClick={() =>
              window.open('https://arma.gg/collections/team-stoic', '_blank')
            }
          />
          <img
            id="merch_t"
            alt="Team Stoic merchandise t-shirt"
            src={stoicMerchT}
            onClick={() =>
              window.open('https://arma.gg/collections/team-stoic', '_blank')
            }
          />
        </section>
        <footer>
          <a href="/">
            <img src={stoicLogo} alt="Team Stoic logo" className="sm_logo" />
          </a>
          <h6 id="copyright">
            &copy; Copyright &nbsp;
            {new Date().getFullYear()}
            &nbsp; Team Stoic
            <br />
            <br />
            Portions of the materials used are trademarks and/or copyrighted
            works of Epic Games, Inc. All rights reserved by Epic. This material
            is not official and is not endorsed by Epic.
          </h6>
        </footer>
        <ToastContainer className="toast-container" />
      </div>
    );
  }
}

export default App;
