import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import './Header.scss';

import stoicLogo from './img/team_stoic_logo.svg';
import discordLogo from './img/social/discord_icon.svg';
import twitchLogo from './img/social/twitch_icon.svg';
import twitterLogo from './img/social/twitter_icon.svg';
import youtubeLogo from './img/social/youtube_icon.svg';
import instaLogo from './img/social/insta_icon.svg';

const defaultWidth = 20;

const Header = () => (
  <header>
    <Navbar expand="lg" id="navbar" fixed="top">
      <Navbar.Brand href="/">
        <img src={stoicLogo} alt="team stoic" className="sm_logo" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse
        id="basic-navbar-nav"
        className="justify-content-end custom-toggler"
      >
        <Nav.Link
          href="https://www.instagram.com/teamstoic/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={instaLogo}
            width={`${defaultWidth - 1}rem`}
            alt="Team Stoic Instagram"
          />
        </Nav.Link>
        <Nav.Link
          href="https://www.youtube.com/channel/UCwVlRBk_fmhniUCD8cVSy2w"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={youtubeLogo}
            width={`${defaultWidth + 3}rem`}
            alt="Team Stoic YouTube Channel"
          />
        </Nav.Link>
        <Nav.Link
          href="https://discordapp.com/invite/N75h8Fd"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={discordLogo}
            width={`${defaultWidth - 2}rem`}
            alt="Team Stoic Discord Server"
          />
        </Nav.Link>
        <Nav.Link
          href="https://www.twitch.tv/team/teamstoic"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={twitchLogo}
            width={`${defaultWidth - 2}rem`}
            alt="Team Stoic Twitch Hub"
          />
        </Nav.Link>
        <Nav.Link
          href="https://twitter.com/TeamStoicGG"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={twitterLogo}
            width={`${defaultWidth}rem`}
            alt="Team Stoic Twitter"
          />
        </Nav.Link>
      </Navbar.Collapse>
    </Navbar>
  </header>
);

export default Header;
