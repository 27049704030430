import React, { Component } from 'react';
import { SlideDown } from 'react-slidedown';
import { Formik, Field, Form } from 'formik';
import { toast } from 'react-toastify';
import axios from 'axios';
import Recaptcha from 'react-recaptcha';

import './ApplicationForm.scss';
import 'react-toastify/dist/ReactToastify.css';

const toastSettings = { autoClose: 5000 };

const validate = values => {
  const errors = {};

  if (values.file && values.file.size > 3000000) {
    const error =
      'File is larger than 3MB. Please choose a smaller file and try again.';
    errors.resume = error;
    toast.error(error, toastSettings);
  }

  if (!values.recaptcha) {
    const error = 'Recaptcha response is required.';
    errors.recaptcha = error;
    toast.error(error, toastSettings);
  }

  return errors;
};

const submitData = (data, actions, onClose) => {
  axios({
    method: 'post',
    url:
      'https://7mvy34hepd.execute-api.us-east-1.amazonaws.com/dev/email/send',
    data
  })
    .then(() => {
      actions.setSubmitting(false);
      onClose();
      toast.success(
        'Application submitted! Thanks for your submission.',
        toastSettings
      );
    })
    .catch(() => {
      actions.setSubmitting(false);
      toast.error(
        `There was an error submitting the application. Please try again later.`,
        toastSettings
      );
    });
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const recaptchaScript = document.createElement('script');
    recaptchaScript.src = 'https://www.google.com/recaptcha/api.js';
    recaptchaScript.async = true;
    recaptchaScript.defer = true;
    document.body.appendChild(recaptchaScript);
  }

  render() {
    const {
      onClose,
      applicationType,
      handleApplicationTypeChange,
      shouldSlide
    } = this.props;

    return (
      <div
        id="ApplicationForm"
        onClick={event => {
          event.stopPropagation();
        }}
      >
        <SlideDown className={shouldSlide ? null : 'inner-slidedown'}>
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            validate={validate}
            initialValues={{ application_type: applicationType, recaptcha: '' }}
            onSubmit={(values, actions) => {
              const data = { ...values };

              if (values.file && applicationType !== 'sponsor') {
                const fileReader = new window.FileReader();
                fileReader.onload = () => {
                  data.file = undefined;
                  [, data.resume_data] = fileReader.result.split(',');

                  submitData(data, actions, onClose);
                };

                data.resume_name = values.file.name;
                fileReader.readAsDataURL(values.file);
              } else {
                submitData(data, actions, onClose);
              }
            }}
            render={({
              errors,
              status,
              touched,
              isSubmitting,
              handleChange,
              setFieldValue
            }) => (
              <div>
                <Form>
                  {applicationType !== 'sponsor' && (
                    <div className="clearfix">
                      <div className="application-type-container">
                        <label htmlFor="application-type-player">
                          <input
                            type="radio"
                            name="application_type"
                            id="application-type-player"
                            checked={applicationType === 'player'}
                            onChange={event => {
                              handleApplicationTypeChange(event);
                              handleChange(event);
                            }}
                            value="player"
                          />
                          PLAYER
                          <span />
                        </label>
                      </div>
                      <div className="application-type-container">
                        <label htmlFor="application-type-staff">
                          <input
                            type="radio"
                            name="application_type"
                            id="application-type-staff"
                            checked={applicationType === 'staff'}
                            onChange={event => {
                              handleApplicationTypeChange(event);
                              handleChange(event);
                            }}
                            value="staff"
                          />
                          STAFF
                          <span />
                        </label>
                      </div>
                    </div>
                  )}
                  <div className="form-section clearfix">
                    <label htmlFor="first-name" id="first-name-label">
                      NAME
                    </label>
                    <Field
                      type="text"
                      name="first_name"
                      id="first-name"
                      placeholder="FIRST"
                      required
                    />
                    <Field
                      type="text"
                      name="last_name"
                      id="last-name"
                      placeholder="LAST"
                      required
                    />
                  </div>
                  {applicationType === 'player' && (
                    <div className="form-section">
                      <label htmlFor="alias" id="alias-label">
                        ALIAS
                      </label>
                      <Field
                        type="text"
                        name="alias"
                        id="alias"
                        placeholder="TEAM_STOIC_PLAYER1"
                        required
                      />
                    </div>
                  )}
                  {applicationType === 'staff' && (
                    <div className="form-section">
                      <label htmlFor="position" id="position-label">
                        POSITION APPLYING FOR:
                      </label>
                      <Field
                        type="text"
                        name="position"
                        id="position"
                        required
                      />
                    </div>
                  )}
                  {applicationType === 'sponsor' && (
                    <div className="form-section">
                      <label htmlFor="company-name">COMPANY NAME</label>
                      <Field
                        type="text"
                        name="company_name"
                        id="company-name"
                      />
                    </div>
                  )}
                  <div className="form-section">
                    <label htmlFor="email">EMAIL</label>
                    <Field type="email" name="email" required />
                  </div>
                  {applicationType === 'player' && (
                    <div className="form-section">
                      <label htmlFor="streamURL">
                        TWITCH / YOUTUBE URL (OPTIONAL)
                      </label>
                      <Field type="text" name="streamURL" id="streamURL" />
                    </div>
                  )}
                  {applicationType === 'sponsor' && (
                    <div className="form-section">
                      <label htmlFor="proposal">BRIEF PROPOSAL:</label>
                      <Field
                        name="proposal"
                        component="textarea"
                        placeholder="Proposal..."
                        id="proposal"
                        maxlength="2000"
                        required
                      />
                    </div>
                  )}
                  {applicationType !== 'sponsor' && (
                    <div className="form-section">
                      <label htmlFor="resume">RESUME</label>
                      <Field
                        type="file"
                        required
                        name="resume"
                        onChange={event => {
                          setFieldValue('file', event.currentTarget.files[0]);
                        }}
                        validate={this.validateResume}
                        accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      />
                    </div>
                  )}
                  <div className="form-section" id="recaptcha">
                    <Recaptcha
                      sitekey="6LcS748UAAAAAL_sgTyYU3PJ6orGPclFPcXEMp3F"
                      render="explicit"
                      theme="dark"
                      verifyCallback={response => {
                        setFieldValue('recaptcha', response);
                      }}
                      onloadCallback={() => {
                        console.log('done loading!');
                      }}
                    />
                  </div>
                  {status && status.msg && <div>{status.msg}</div>}
                  <div id="submit-close-container" className="clearfix">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="submit-close"
                    >
                      SUBMIT
                    </button>
                    <button
                      type="button"
                      className="submit-close"
                      onClick={event => onClose(event)}
                    >
                      CLOSE
                    </button>
                  </div>
                </Form>
              </div>
            )}
          />
        </SlideDown>
      </div>
    );
  }
}

export default App;
