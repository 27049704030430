import React, { Component } from 'react';
import './PlayerContainer.scss';

import twitchLogo from './img/social/twitch_icon.svg';
import twitterLogo from './img/social/twitter_icon.svg';

const PlayerContainer = ({ player }) => {
  return (
    <div
      className="player_container clearfix"
      onClick={event => {
        event.stopPropagation();
      }}
    >
      <div class="player_profile">
        <img
          className="player_profile"
          src={player.profile}
          alt={`${player.playerName} Profile`}
        />
      </div>
      <div className="player_content">
        <h1>{player.playerName}</h1>
        <div className="player_social_links">
          <a
            href={`http://www.twitter.com/${player.twitter}`}
            target="_blank"
            rel="noopener noreferrer"
            className="player_social_link"
          >
            <img
              className="player_social_logo"
              src={twitterLogo}
              alt="Twitter"
            />
            {`@${player.twitter}`}
          </a>
          <a
            href={`http://www.twitch.tv/${player.twitch}`}
            target="_blank"
            rel="noopener noreferrer"
            className="player_social_link"
          >
            <img className="player_social_logo" src={twitchLogo} alt="Twitch" />
            {`twitch.tv/${player.twitch}`}
          </a>
        </div>
        <p>{player.description}</p>
      </div>
    </div>
  );
};

export default PlayerContainer;
