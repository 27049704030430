import React, { Component } from 'react';
import { scroller, Element } from 'react-scroll';
import { SlideDown } from 'react-slidedown';
import './TeamContainer.scss';
import PlayerContainer from './PlayerContainer';
import backgroundFortnite from './img/fortnite_graphic_loading.jpg';
import backgroundDota2 from './img/dota2_graphic_loading.jpg';

import upArrow from './img/arrow_up.svg';

// player profiles
import DeviousProfile from './img/player_profiles/Devious_player_profile.png';
import FrostyZKProfile from './img/player_profiles/FrostyZK_player_profile.png';
import IcyProfile from './img/player_profiles/Icy_player_profile.png';
import NahvProfile from './img/player_profiles/Nahv_player_profile.png';

class TeamContainer extends Component {
  constructor() {
    super();
    this.teams = [{ teamName: 'fortnite', background: backgroundFortnite }];
    this.state = {};
    this.players = [
      {
        playerName: 'FrostyZK',
        twitter: 'FrostyZK',
        twitch: 'frostyzk',
        description:
          'I have always been told I had talent at every game I’ve tried but it wasn’t just until Fortnite that things came together. This is only the beginning for me as a professional gamer. I can’t wait to see where Stoic and esports goes, knowing I’ll be apart of it.',
        profile: FrostyZKProfile
      },
      {
        playerName: 'Devious',
        twitter: 'DeviousFN',
        twitch: 'DeviousFN',
        description:
          "Fortnite instantly caught my eye when I first started playing it. I knew this was the game I wanted to persue as a profession. This game has one of the biggest skillgaps and great fluidity which makes it one on my favorites.  Fortnite has changed my life. I hope my work continues to pay off in Fortnite, and I'm excited for the future in this game.",
        profile: DeviousProfile
      },
      {
        playerName: 'Nahv',
        twitter: 'Nahv__',
        twitch: 'Nahv_',
        description:
          'I fell in love with Fortnite in season one. Since then, I signed with Stoic to take the next step to become a successful streamer as well as a professional battle royale player. I enjoy competing at highest levels in Fortnite and being on Stoic makes me feel like anything is achievable with the people on this team.',
        profile: NahvProfile
      },
{
        playerName: 'Icy',
        twitter: 'IcyFN',
        twitch: 'IcyGotBands',
        description:
          "Competing at the top level is what makes me love Fortnite. I can't wait to play at events with some of my best friends under a really great organization. I plan to stay in esports for as long as possible to make a career within the gaming industry. Signing with Stoic was a next step to something great.",
        profile: IcyProfile
      }
    ];
  }

  expandAndScroll(teamName) {
    this.setState(prevState => {
      scroller.scrollTo('teamScroll', {
        duration: 800,
        smooth: 'easeInOutQuart',
        offset: -100,
        isDynamic: true
      });

      if (prevState.expandedTeam !== teamName) {
        return {
          expandedTeam: teamName
        };
      }

      return {
        expandedTeam: ''
      };
    });
  }

  render() {
    const { expandedTeam } = this.state;

    return (
      <section id="teams">
        <Element name="teamScroll" />
        {this.teams.map(team => {
          return (
            <div
              key={team.teamName}
              className="team"
              onClick={() => {
                this.expandAndScroll(team.teamName);
              }}
            >
              <h2
                className={`team_name ${
                  team.teamName === expandedTeam ? 'active' : ''
                }`}
                style={{
                  backgroundImage: `url(${team.background})`
                }}
              >
                {team.teamName.toUpperCase()}
                <h2 className="meet_players">MEET THE PLAYERS</h2>
              </h2>
              <SlideDown className="team">
                {expandedTeam === team.teamName ? (
                  <div>
                    {this.players.map(player => {
                      return (
                        <PlayerContainer player={player} key={player.name} />
                      );
                    })}
                    <div id="hide_team">
                      <img src={upArrow} alt="Close" />
                      <span> HIDE </span>
                      <img src={upArrow} alt="Close" />
                    </div>
                  </div>
                ) : null}
              </SlideDown>
            </div>
          );
        })}
      </section>
    );
  }
}

export default TeamContainer;
